
export const Star = ({ size, name, color }) => {
  const sizes = {
    1: 'size-1',
    2: 'size-2',
    3: 'size-3',
    4: 'size-4',
  }
  return <div
    className={`${color} ${size ? `${sizes[size]}` : `${name ? 'text-sm w-full bg-left ps-5' : ''} `} bg-contain bg-no-repeat`}>
    {name && <span className="ps-6 w-32 block text-sm">{name}</span>}
  </div>
}