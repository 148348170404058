import Container from "@/components/atoms/Container";
import Section from "@/components/atoms/Section";

const SpinnerSegment = ({ children, className }) => {
    return <Section className="text-center">
        <div className="loading">
            Loading<span className="dots">...</span>
        </div>
    </Section>
}
export default SpinnerSegment;