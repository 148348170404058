import { useEffect, useState } from "react";
import Input from "../ui/Input"
import { useLocaleContext } from "@/contexts/LocaleContext";
export const InputSearchLocation = ({ field, onSearch }) => {
    const { lang } = useLocaleContext();
    const [query, setQuery] = useState()
    const [queryData, setQueryData] = useState()
    useEffect(() => {
        if (query && query.length > 2) {
            const timeOutId = setTimeout(
                () => {
                    setQueryData('')
                    onSearch(query)
                }, 1000);
            return () => clearTimeout(timeOutId);
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps
    return <><Input
        field={field}
        onChange={(str) => setQuery(JSON.parse(sessionStorage.getItem('destination'))['name'] + ', ' + str)}
    />
    </>
}
