"use client";
import { createContext, useContext, useState } from "react";
import GetLocale from "@/components/GetLocale";
import { getCookieClient, setCookieClient } from "@/api/actions";

!getCookieClient('lang') ? setCookieClient("lang", process.env.DEFAULT_LANG) : null;
!getCookieClient('currency') ? setCookieClient("currency", process.env.DEFAULT_CURRENCY) : null;

export const LocaleContext = createContext({
  me: {},
  lang: "",
  locale: "",
  currency: "",
  setMe: () => { },
  setLang: () => { },
  setLocale: () => { },
  setCurrency: () => { },
  showGallery: "",
  setShowGallery: () => { }
});

export const LocaleContextProvider = ({ children }) => {

  const [me, setMe] = useState(null);
  const [lang, setLang] = useState(null);
  const [locale, setLocale] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [showGallery, setShowGallery] = useState(false)

  return (
    <LocaleContext.Provider
      value={{
        me,
        lang,
        locale,
        currency,
        setMe,
        setLang,
        setCurrency,
        setLocale,
        showGallery,
        setShowGallery
      }}
    >
      <GetLocale>{children}</GetLocale>
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => {
  return useContext(LocaleContext);
};
