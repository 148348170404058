"use client";

// import {PageLocale} from '@/graphql/PageLocale.graphql';
// import {LocaleContext} from "@/contexts/LocaleContext";
// import {usePathname} from "next/navigation";
// import {useContext, useEffect} from "react";
// import {gql, useQuery, useSuspenseQuery} from "@apollo/client";
// import {getLocale} from "@/api/locales";
// import { PageLocale } from "@/graphql/PageLocale.graphql";
// import { executeGraphQl } from "@/api/graphqlApi";

const GetLocale = ({children}) => {
    // const {setLocale} = useContext(LocaleContext);
    // const pathname = usePathname();
    // const {loading,error, data} = useSuspenseQuery(PageLocale, {variables: {path: pathname, lang: "pl"}, pollInterval: 3000, notifyOnNetworkStatusChange: true})
    // useEffect(() => {
    //     if (!loading && data.locale.code === 200) {
    //         setLocale(data.locale.data);
    //     }
    // }, [loading]);

    // getLocale({path: pathname, lang: "pl"}).then((data) => {
    //     setLocale(data.data.locale.data);
    // });

    // const { loading, error, data } = executeGraphQl({
    //     query: PageLocale,
    //     variables: { path: pathname, lang: "pl" },
    //     cache: "no-store",
    //   });


    return children
};
export default GetLocale;
