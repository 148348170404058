'use client'
import { Star } from "@/components/atoms/Star";
import { useState } from "react";

const Stars = ({ className, number, name, color, size }) => {
    const [bgcolor] = useState(color ? 'bg-star-' + color : 'bg-star')
    return <div className={`${className ? className : ''} w-auto inline-flex gap-x-0.5 cursor-pointer text-blue-5 font-reg select-none`}>
        {Array.from({ length: number }, (e, i) => <Star key={i} size={size} name={name} color={bgcolor} />)}
    </div>
}
export default Stars;