'use client';
import {
    GoogleMap, useLoadScript, Marker, InfoWindow, MarkerClusterer,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import Stars from "@/components/atoms/FormUi/Stars";
import Card from "@/components/atoms/Card";
import { RateBadge } from "@/components/atoms/Rating/RateBadge";
import { useSearchContext } from "@/contexts/SearchContexts";
import SpinnerSegment from "@/components/molecules/ui/SpinnerSegment";
import { useLocaleContext } from "@/contexts/LocaleContext";
import { InputSearchLocation } from "@/components/molecules/Map/InputSearchLocation";
const styles = null;//require('./GoogleMapStyles.json')

const Mapa = ({ params, hotelsData, sendActiveHotel, scrollToCard }) => {
    const { locale } = useLocaleContext();
    const [markers, setMarkers] = useState(hotelsData)
    const [centerPosition, setCenterPosition] = useState([])
    const [zoom, setZoom] = useState(11)
    const [gridSize] = useState(5)
    const [location, setLocation] = useState(centerPosition)
    const [mapRef, setMapRef] = useState();
    const [infoWindowData, setInfoWindowData] = useState(0);
    const [markerIcon, setMarkerIcon] = useState('/pin.svg')
    const [markerIconBig, setMarkerIconBig] = useState('/pin-big.svg')
    const [isOpen, setIsOpen] = useState(null);
    const { destination } = useSearchContext()
    const sparams = useSearchParams()
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    });
    const searchAddress =
    {
        "name": "searchAddress",
        "type": "input",
        "attr": {
            "type": "text",
            "placeholder": "wyszukaj lokalizację",
            "minLength": 3
        }
    }

    const path = usePathname();
    useEffect(() => {


        if (path === '/') {
            // static lat and lng for home page

            setCenterPosition(prevPos => {
                return {
                    ...prevPos, lat: params.location.lat, lng: params.location.lng + 0.05, zoom: zoom
                }
            })
            setLocation(prevPos => {
                return {
                    ...prevPos, lat: params.location.lat, lng: params.location.lng
                }
            })
            setMarkerIcon('/media/icons/blue-pin.svg')

        } else if (hotelsData) {
            if (hotelsData.length === 0) {
                findCoordinates(destination.name).then(e => setCenterPosition((prevState) => calculateCenterPosition(e, 3)))

            } else {
                // set center map for hotels, hotel page
                setCenterPosition((prevState) => calculateCenterPosition(hotelsData, zoom))
            }

        }
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sendActiveHotel)
            handleMarkerClick(sendActiveHotel.id, sendActiveHotel.hotel)
        if (!sendActiveHotel) {
            handleMarkerClick(null, null)

        }
    }, [sendActiveHotel]) // eslint-disable-line react-hooks/exhaustive-deps


    const onMapLoad = (map) => {
        setMapRef(map);
        // const bounds = new google.maps.LatLngBounds();
        // hotelsData?.map(({latitude, longitude}) => bounds.extend({lat: latitude, lng: longitude}));
        // map.fitBounds(bounds);

    };
    const handleMarkerClick = (id, hotel, open) => {
        if (!id && !hotel) {
            // mapRef?.panTo(centerPosition);
            // mapRef?.setZoom(zoom);
            // setInfoWindowData(null);
            setIsOpen(null);
        } else {

            // for one marker
            // mapRef?.panTo({ lat: hotel.latitude || hotel.static_vm.latitude, lng: hotel.longitude || hotel.static_vm.longitude });
            // mapRef?.setZoom(14);
            setInfoWindowData({ id, hotel });
            setIsOpen(hotel.id);

            // one marker in hotel page
            if (typeof id === 'string') {
                setMarkers([hotelsData])
                setIsOpen(hotel.id)
                setMarkerIcon('/blue-pin.svg')
            }
            if (typeof id === 'number' && open) {
                window.open(`/dashboard/hotel?hotel_id=${hotel.hotel_id}&${sparams}`)
                // mapRef?.panTo({lat: hotel.lat ,lng: hotel.lng})
                // scrollToCard(id)
            }
        }
    };

    if (!isLoaded) return <div className="text-blue-50 p-40"><SpinnerSegment /></div>;


    //{lat: 52.288110, lng: 20.862151}
    const onMarkerClustererClick = (markerClusterer) => {
    }
    const onMarker = (markerId, marker, c) => {
        if (markers.length > 1) {
            marker.setIcon(markerIconBig)
            setIsOpen(markerId)
        }
    }
    const outMarker = (markerId, marker) => {
        if (markers.length > 1) {
            marker.setIcon(markerIcon)
            setIsOpen(null)
        }
    }

    const onSearchHandler = (address) => {
        findCoordinates(address).then(coords => {
            setLocation({ lat: coords.latitude, lng: coords.longitude })
            setCenterPosition({ lat: coords.latitude, lng: coords.longitude, zoom: 16 })
        })
    }
    return (<GoogleMap
        zoom={centerPosition.zoom || zoom}
        center={centerPosition}
        mapContainerClassName="map"
        options={{ streetViewControl: false, mapTypeControl: false, styles: styles }}
        onLoad={onMapLoad}
        mapContainerStyle={{ width: "100%", height: "100%", margin: "auto" }}>
        {/* single map marker */}
        {params && <Marker position={location} icon={markerIcon} />}

        {/* multi-maps-markers  */}
        <MarkerClusterer
            onClick={onMarkerClustererClick}
            averageCenter
            enableRetinaIcons="true"
            gridSize={gridSize}
        >
            {(clusterer) => (
                <div>
                    {markers?.length >= 1 && markers.filter(marker => marker.isVisible).map((hotel, id) => (<Marker
                        key={id}
                        clusterer={clusterer}
                        icon={markerIcon}
                        onMouseOver={() => onMarker(hotel.hotel_id, clusterer.getMarkers()[id], clusterer)}
                        onMouseOut={() => outMarker(hotel.hotel_id, clusterer.getMarkers()[id])}
                        animation={google.maps.Animation.DROP}
                        onClick={() => handleMarkerClick(id, hotel, true)}
                        zIndex={99999}
                        position={{ lat: hotel.latitude, lng: hotel.longitude }}>
                        {markers.length === 1 && infoWindowData && (
                            <InfoWindow position={{ lat: infoWindowData.hotel.latitude, lng: infoWindowData.hotel.longitude }}>
                                <Card className="flex justify-end bg-contain bg-no-repeat bg-left marker-bg min-w-64"
                                    bgImage={infoWindowData.hotel.images}>
                                    {infoWindowData?.hotel?.rating_v2?.rating && <RateBadge className="right-2 font-reg !h-9 !w-9 !leading-5 absolute" score={infoWindowData?.hotel.rating_v2.rating} />}
                                    <Card className="w-2/3 px-3 py-2 pb-8 pe-4 text-dark">
                                        <Stars number={infoWindowData.hotel?.star_rating} color="gold" size="2" />
                                        <div className="text-lg font-semibold">{infoWindowData?.hotel.name}</div>
                                    </Card>
                                </Card>
                            </InfoWindow>
                        )}
                        {isOpen === hotel.hotel_id && (
                            <InfoWindow className={`text-lg text-dark text-right triangle`} position={{ lat: hotel.latitude, lng: hotel.longitude }}>
                                <div className="text-lg text-dark text-right overflow-hidden px-3 py-1">
                                    {hotel.stars > 0 && <Stars number={hotel?.stars} color="gold" size="2" />}
                                    <div className="text-lg font-semibold">{hotel.name}</div>
                                    <div className="text-sm text-right mt-1 text-blue-50 font-semibold p-0 m-0">{hotel.price}</div>
                                </div>
                            </InfoWindow>)}
                        {/* {!isOpen === !hotel.hotel_id && (<InfoWindow className={`rounded-xl`} position={{ lat: hotel.latitude, lng: hotel.longitude }}>
                            <div className="px-1.5 py-0">
                                <div className="text-sm text-right text-blue-100 font-semibold p-0 m-0">{hotel.price}</div>
                            </div>
                        </InfoWindow>)} */}
                    </Marker>))}
                </div>
            )}
        </MarkerClusterer>
        <div className="absolute top-1 right-16">
            <InputSearchLocation field={searchAddress} onSearch={onSearchHandler} />
        </div>
    </GoogleMap>);
};

const calculateCenterPosition = (data, zoom) => {
    let latArr = !data.latitude ? data.map(item => item.latitude) : data.latitude;
    let lngArr = !data.longitude ? data.map(item => item.longitude) : data.longitude;

    if (!data.latitude && !data.longitude) {
        const latMax = Math.max(...latArr);
        const latMin = Math.min(...latArr);
        const lngMax = Math.max(...lngArr);
        const lngMin = Math.min(...lngArr);

        const centerLat = (latMax + latMin) / 2;
        const centerLng = (lngMax + lngMin) / 2;

        const maxDistance = Math.max(latMax - latMin, lngMax - lngMin);

        let calculatedZoom = getZoomLevel(maxDistance);

        return {
            lat: centerLat,
            lng: centerLng,
            zoom: calculatedZoom
        }
    } else {
        return { lat: latArr, lng: lngArr, zoom: zoom }
    }
}

const getZoomLevel = (maxDistance) => {
    // Zakładamy, że szerokość mapy wynosi 1024 piksele, a wysokość 768 piksele
    const mapWidth = 20;
    const mapHeight = 20;

    // Oblicz odpowiedni zoom dla szerokości i wysokości mapy
    const zoomWidth = Math.floor(8 - Math.log(maxDistance / (360 / mapWidth)) / Math.log(2));
    const zoomHeight = Math.floor(8 - Math.log(maxDistance / (180 / mapHeight)) / Math.log(2));

    // Wybierz mniejszy z dwóch poziomów zoomu, aby upewnić się, że wszystkie punkty są widoczne
    return Math.min(zoomWidth, zoomHeight);
}

const findCoordinates = async (address) => {
    const apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === 'OK') {
            const { lat, lng } = data.results[0].geometry.location;
            console.log(`Znalezione współrzędne: ${lat}, ${lng}`);
            return { latitude: lat, longitude: lng };
        } else {
            console.log('Nie znaleziono adresu.');
            return [{ latitude: 0, longitude: 0 }];
        }
    } catch (error) {
        console.error('Wystąpił błąd podczas wyszukiwania adresu: ', error);
        return null;
    }
}

export default Mapa;